import UploadIcon from '@mui/icons-material/Upload';
import { Alert, Box, Button, Grid, Modal, Snackbar, Typography } from '@mui/material';
import { CSVImporter } from 'csv-import-react';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CreateCustomFieldValueDto,
  CreateTokenListItemDto,
  CustomFieldLabelDto,
  TokenListDto,
  TokenListItemDto,
  UpdateTokenListItemDto,
} from '../api/api';
import EditableTable from '../components/EditableTable';
import ListInfo from '../components/ListInfo';
import ListModal from '../components/ListModal';
import PartnerModal from '../components/Partner/PartnerModal';
import PartnerList from '../components/PartnerList';
import { useNetworksList } from '../hooks/useNetworksList';
import { usePartnersList } from '../hooks/usePartnersList';
import { ListModalType, PartnerModalType } from '../types/modalType';
import { Column } from '../types/partnerListColumnType';
import { formatLabelToLowerCase, generateTemplate, getNetworkIdByName, isAddressValid } from '../utils/helpers';

const ListPage: React.FC = () => {
  const { listId } = useParams<{ listId: string }>();
  const { partnerList, isLoading, error, addToken, updateToken } = usePartnersList(listId);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [modalType, setModalType] = useState<PartnerModalType>(PartnerModalType.ADD_PARTNER);
  const partnerRef = useRef<TokenListItemDto | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [modifiedTokens, setModifiedTokens] = useState<Record<number, UpdateTokenListItemDto>>({});
  const [hasValidationError, setHasValidationError] = useState(false);
  const { networksList } = useNetworksList();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [importColumns, setImportColumns] = useState<Column[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleImportComplete = (data: { rows: { values: Record<string, any> }[] }) => {
    const existingCombinations = new Set(
      partnerList.items.map((partner) => `${partner.token.networkId}_${partner.token.address.toLowerCase()}`)
    );

    const rowErrors: string[] = [];

    const validateCustomField = (
      key: string,
      value: any,
      partnerList: TokenListDto,
      errorsForRow: string[]
    ): CreateCustomFieldValueDto | null => {
      const customFieldLabel = partnerList.customFieldsLabels.find(
        (label: CustomFieldLabelDto) => formatLabelToLowerCase(label.name + '_' + label.customFieldType) === key
      );

      if (!customFieldLabel) {
        errorsForRow.push(`Custom field not found for key: ${key}`);
        return null;
      }

      const { customFieldType, customFieldId, defaultValue } = customFieldLabel;

      const typeValidators: Record<
        CustomFieldLabelDto.CustomFieldTypeEnum,
        (value: any, defaultValue: any) => { isValid: boolean; value: any }
      > = {
        [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (value, defaultValue) => {
          const normalizedValue = (value || defaultValue || '').toString().toLowerCase();
          const isValid = [null, undefined, '', 'true', 'false'].includes(normalizedValue);
          return { isValid, value: isValid ? normalizedValue || 'false' : null };
        },
        [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (value, defaultValue) => {
          const isValid = value === null || value === undefined || value === '' || !isNaN(Number(value));
          return { isValid, value: isValid ? value || defaultValue : null };
        },
        [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (value, defaultValue) => {
          if (value) {
            const date = dayjs(value);
            const isValid = date.isValid();
            return { isValid, value: isValid ? date.format('YYYY-MM-DD') : null };
          }
          return { isValid: true, value: defaultValue || '' };
        },
        [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (value, defaultValue) => ({
          isValid: true,
          value: value || defaultValue,
        }),
      };

      const validator = typeValidators[customFieldType];

      if (!validator) {
        errorsForRow.push(`Unsupported field type for "${key}": ${customFieldType}`);
        return null;
      }

      const { isValid, value: validatedValue } = validator(value, defaultValue);

      if (!isValid) {
        errorsForRow.push(`Invalid value for ${customFieldType} field "${key}": ${value}`);
        return null;
      }

      return { customFieldId, value: validatedValue };
    };

    const validatedData = data.rows
      .map((row, index) => {
        const { values } = row;
        const { address, networkName, note, ...customFields } = values;

        const errorsForRow: string[] = [];

        if (!isAddressValid(address, networkName)) {
          errorsForRow.push(`Invalid Partner: ${address}`);
        }

        const networkId = getNetworkIdByName(networkName, networksList);
        if (!networkId) {
          errorsForRow.push(`Network not found: ${networkName}`);
        }

        const combinationKey = `${networkId}_${address.toLowerCase()}`;
        if (existingCombinations.has(combinationKey)) {
          errorsForRow.push(`Duplicate combination of Network and Partner: ${networkName}, ${address}`);
        } else {
          existingCombinations.add(combinationKey);
        }

        const customFieldsValues = Object.entries(customFields)
          .map(([key, value]) => validateCustomField(key, value, partnerList, errorsForRow))
          .filter(Boolean) as CreateCustomFieldValueDto[];

        if (errorsForRow.length > 0) {
          rowErrors.push(`${index + 1}. ${errorsForRow.join(', ')}`);
        }

        return {
          listId: parseInt(listId || '0', 10),
          address,
          networkId,
          note: note || '',
          customFieldsValues,
        };
      })
      .filter(Boolean) as CreateTokenListItemDto[];

    if (rowErrors.length > 0) {
      setIsOpen(false);
      setErrorMessages(rowErrors);
      return;
    }

    validatedData.forEach(async (partner) => {
      try {
        await addToken(partner);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setErrorMessages((prev) => [...prev, `Error adding partner ${partner.address}: ${errorMessage}`]);
      }
    });
  };

  const handleOpenPartnerModal = (type: PartnerModalType) => {
    setModalType(type);
    setIsPartnerModalOpen(true);
  };

  const handleListModalClick = () => {
    setIsListModalOpen(!isListModalOpen);
  };

  const handleSubmit = async (formValues: CreateTokenListItemDto | UpdateTokenListItemDto) => {
    const actions: Record<PartnerModalType, (data: any) => Promise<void>> = {
      [PartnerModalType.ADD_PARTNER]: async (data: CreateTokenListItemDto) => {
        await addToken(data);
      },
      [PartnerModalType.EDIT_PARTNER]: async (data: UpdateTokenListItemDto) => {
        await updateToken(data, String(partnerRef.current?.id));
      },
    };

    await actions[modalType](formValues);
    setIsPartnerModalOpen(false);
  };

  const handleRowClick = (partner: TokenListItemDto) => {
    partnerRef.current = partner;
    handleOpenPartnerModal(PartnerModalType.EDIT_PARTNER);
  };

  const toggleEditMode = () => {
    setIsEditing((prevState) => !prevState);
  };

  const handleTokenChange = (id: number, updatedToken: Partial<UpdateTokenListItemDto>) => {
    setModifiedTokens((prev) => ({
      ...prev,
      [id]: { ...prev[id], ...updatedToken },
    }));
  };

  const handleValidationError = (hasError: boolean) => {
    setHasValidationError(hasError);
  };

  const handleSave = async () => {
    setIsSaveModalOpen(false);
    await Promise.all(Object.entries(modifiedTokens).map(([id, updatedToken]) => updateToken(updatedToken, id)));
    setModifiedTokens({});
    setIsEditing(false);
  };

  const handleCancel = () => {
    setModifiedTokens({});
    setIsEditing(false);
  };

  if (!listId || isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const modifiedTokenCount = Object.keys(modifiedTokens).length;

  return (
    <div>
      <ListInfo name={partnerList?.name} note={partnerList?.note} handleSettingsClick={handleListModalClick} />
      {partnerList && (
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
          {!isEditing && (
            <Grid item>
              <Button onClick={toggleEditMode} variant="outlined">
                EDIT
              </Button>
            </Grid>
          )}
          {isEditing && (
            <>
              <Grid item>
                <Button variant="outlined" color="error" onClick={handleCancel}>
                  CANCEL
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setIsSaveModalOpen(true)}
                  disabled={modifiedTokenCount === 0 || hasValidationError}>
                  SAVE
                </Button>
              </Grid>
            </>
          )}
          <Grid item>
            <Button onClick={() => handleOpenPartnerModal(PartnerModalType.ADD_PARTNER)} variant="contained">
              ADD PARTNER
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setIsOpen(true)} variant="contained" startIcon={<UploadIcon />}>
              Import
            </Button>
          </Grid>
          <CSVImporter
            modalIsOpen={isOpen}
            modalOnCloseTriggered={() => setIsOpen(false)}
            darkMode={false}
            modalCloseOnOutsideClick={true}
            primaryColor="#2196f3"
            onComplete={handleImportComplete}
            template={generateTemplate(importColumns)}
          />
          <Snackbar
            open={errorMessages.length > 0}
            onClose={() => {
              setErrorMessages([]);
            }}>
            <Alert
              onClose={() => {
                setErrorMessages([]);
              }}
              severity="error"
              variant="filled"
              sx={{ width: '99%' }}>
              {errorMessages.map((error, index) => (
                <span key={index}>
                  {error}
                  <br />
                </span>
              ))}
            </Alert>
          </Snackbar>
        </Grid>
      )}

      {partnerList ? (
        isEditing ? (
          <EditableTable
            list={partnerList}
            onTokenChange={handleTokenChange}
            onValidationError={handleValidationError}
          />
        ) : (
          <PartnerList list={partnerList} handleRowClick={handleRowClick} setImportColumns={setImportColumns} />
        )
      ) : (
        <div>No data available</div>
      )}

      <PartnerModal
        type={modalType}
        open={isPartnerModalOpen}
        onClose={() => setIsPartnerModalOpen(false)}
        listId={partnerList?.id ?? 0}
        onSubmit={handleSubmit}
        partnerData={modalType === PartnerModalType.EDIT_PARTNER && partnerRef.current ? partnerRef.current : undefined}
        customFieldsLabels={partnerList?.customFieldsLabels}
      />

      <ListModal
        open={isListModalOpen}
        onClose={handleListModalClick}
        tokenList={partnerList}
        type={ListModalType.EDIT_LIST}
      />
      <Modal open={isSaveModalOpen} onClose={() => setIsSaveModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxWidth: 400,
            textAlign: 'center',
          }}>
          <Typography variant="h6" component="h2" mb={2}>
            Confirm Changes
          </Typography>
          <Typography variant="body1" mb={4}>
            Do you want to update {Object.keys(modifiedTokens).length} partners?
          </Typography>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="error" onClick={() => setIsSaveModalOpen(false)}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ListPage;
