import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import LayoutRoutes from './components/LayoutRoutes';
import OpenLayoutRoutes from './components/OpenLayoutRoutes';
import { BASE_PATH } from './constants/routes';
import { useUserInfo } from './hooks/useUserInfo';
import AnalyticsPage from './pages/AnalyticsPage';
import AuthPage from './pages/AuthPage';
import ListPage from './pages/ListPage';
import NotFound from './pages/NotFound';
import ProjectPage from './pages/ProjectPage';
import SettingsPage from './pages/SettingsPage';
import WelcomePage from './pages/WelcomePage';
import { baseTheme } from './theme';

const RedirectToFirstProject: React.FC = () => {
  const { firstProjectLink } = useUserInfo();
  return <Navigate to={firstProjectLink} replace />;
};

const router = createBrowserRouter([
  {
    path: BASE_PATH.AUTH,
    element: (
      <OpenLayoutRoutes>
        <AuthPage />
      </OpenLayoutRoutes>
    ),
  },
  {
    path: BASE_PATH.WELCOME,
    element: (
      <OpenLayoutRoutes>
        <WelcomePage />
      </OpenLayoutRoutes>
    ),
  },
  {
    path: BASE_PATH.HOME,
    element: <LayoutRoutes />,
    children: [
      {
        index: true,
        element: <RedirectToFirstProject />,
      },
      {
        path: BASE_PATH.PROJECT,
        element: <ProjectPage />,
      },
      {
        path: BASE_PATH.LIST,
        element: <ListPage />,
      },
      {
        path: BASE_PATH.SETTINGS,
        element: <SettingsPage />,
      },
      {
        path: BASE_PATH.ANALYTICS,
        element: <AnalyticsPage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={baseTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
