import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { ActionDto, CreateActionDto, ProjectInfoDto, UpdateActionDto } from '../api/api';
import { useActionsManagement } from '../hooks/useActionsManagement';
import { useProjectInfo } from '../hooks/useProjectInfo';
import { CustomFormType } from '../types/customFormType';
import { DialogAction } from '../types/DialogType';
import { OrderType } from '../types/sortingType';
import { getComparator } from '../utils/helpers';
import ConfirmDialog from './ConfirmDialog';
import CustomFormBlock from './CustomFormBlock';
import SimpleCard from './SimpleCard';

interface ActionsBlockProps {
  projectInfo: ProjectInfoDto | undefined;
}

type ActionState = (ActionDto | CreateActionDto)[];

const ActionsBlock: React.FC<ActionsBlockProps> = ({ projectInfo }) => {
  const projectId = projectInfo?.id.toString() || '';
  const { actionsList, createNewAction, updateExistingAction, isLoading } = useActionsManagement(projectId);
  const { updateProjectInfo, isUpdating } = useProjectInfo(projectId);

  const [formState, setFormState] = useState({
    name: projectInfo?.name || '',
    description: projectInfo?.note || '',
    actions: [] as ActionState,
  });

  const [initialState, setInitialState] = useState(formState);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | null>(null);
  const [dialogAction, setDialogAction] = useState<DialogAction | null>(null);

  const [order, setOrder] = useState<OrderType>(OrderType.ASC);
  const [orderBy, setOrderBy] = useState<keyof ActionDto>('isArchived');

  useEffect(() => {
    if (actionsList) {
      const sortedActions = [...actionsList]
        .sort(getComparator<ActionDto>(OrderType.ASC, 'id'))
        .sort(getComparator<ActionDto>(order, orderBy));
      setFormState({
        name: formState.name,
        description: formState.description,
        actions: sortedActions,
      });
      setInitialState({
        name: formState.name,
        description: formState.description,
        actions: sortedActions,
      });
    }
  }, [actionsList]);

  const handleSortRequest = (property: keyof ActionDto) => {
    const isAsc = orderBy === property && order === OrderType.ASC;
    const newOrder = isAsc ? OrderType.DESC : OrderType.ASC;
    setOrder(newOrder);
    setOrderBy(property);

    if (formState.actions) {
      formState.actions = [
        ...formState.actions
          .filter((action): action is ActionDto => 'id' in action)
          .sort(getComparator<ActionDto>(newOrder, property)),
        ...formState.actions.filter((action): action is CreateActionDto => !('id' in action)),
      ];
    }
  };

  const handleAddAction = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      actions: [
        ...prevState.actions,
        {
          name: '',
          note: '',
        } as CreateActionDto,
      ],
    }));
  }, []);

  const handleCancel = useCallback(() => {
    setFormState(initialState);
  }, [initialState]);

  const handleChange = (index: number, key: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      actions: prevState.actions.map((action, i) => (i === index ? { ...action, [key]: value } : action)),
    }));
  };

  const handleFieldChange = useCallback((field: 'name' | 'description', value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const handleDialogOpen = (index: number, action: DialogAction) => {
    setSelectedActionIndex(index);
    setDialogOpen(true);
    setDialogAction(action);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedActionIndex(null);
    setDialogAction(null);
  };

  const handleDialogConfirm = async () => {
    if (selectedActionIndex !== null && dialogAction) {
      setLoading(true);

      const updatedActions = formState.actions.map((action, i) =>
        i === selectedActionIndex && 'isArchived' in action
          ? { ...action, isArchived: dialogAction === DialogAction.REMOVE }
          : action
      );

      setFormState((prevState) => ({
        ...prevState,
        actions: updatedActions,
      }));

      try {
        const actionsToUpdate = updatedActions.filter((action): action is ActionDto => 'id' in action);

        if (actionsToUpdate.length > 0) {
          await Promise.all(
            actionsToUpdate.map((action) =>
              updateExistingAction(action.id.toString(), {
                name: action.name,
                note: action.note,
                isArchived: action.isArchived,
              } as UpdateActionDto)
            )
          );
        }
      } catch {
        alert('Failed to update actions. Please try again.');
      } finally {
        setLoading(false);
      }

      handleDialogClose();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await updateProjectInfo({
        newName: formState.name,
        newNote: formState.description,
        isArchived: false,
      });

      const actionsToUpdate = formState.actions.filter((action): action is ActionDto => 'id' in action);
      const actionsToCreate = formState.actions.filter((action): action is CreateActionDto => !('id' in action));

      const updatePromises = actionsToUpdate.map((action) =>
        updateExistingAction(action.id.toString(), {
          name: action.name,
          note: action.note,
          isArchived: action.isArchived,
        } as UpdateActionDto)
      );

      const createPromises = actionsToCreate.map((action) =>
        createNewAction({
          name: action.name,
          note: action.note,
        } as CreateActionDto)
      );

      await Promise.all([...updatePromises, ...createPromises]);
    } catch (error) {
      console.error('Submission failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleCard>
      <Typography variant="h6" mb={1.5}>
        Project Details
      </Typography>

      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle1" color="textSecondary">
          Name
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={formState.name}
          onChange={(e) => handleFieldChange('name', e.target.value)}
        />

        <Typography variant="subtitle1" color="textSecondary">
          Description
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={formState.description}
          onChange={(e) => handleFieldChange('description', e.target.value)}
        />

        <CustomFormBlock
          type={CustomFormType.ACTIONS}
          formState={formState}
          handleChange={handleChange}
          handleAddItem={handleAddAction}
          handleDialogOpen={handleDialogOpen}
          order={order}
          orderBy={orderBy}
          handleSortRequest={handleSortRequest}
        />
      </Box>

      <Box display="flex" justifyContent="center" gap={2} mt={3}>
        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading || isLoading || isUpdating}>
          {loading || isUpdating ? 'Submitting...' : 'Confirm'}
        </Button>
      </Box>

      <ConfirmDialog
        open={dialogOpen}
        action={dialogAction}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </SimpleCard>
  );
};

export default ActionsBlock;
