import { isAddress as isEthereumAddressValid } from '@ethersproject/address';
import dayjs from 'dayjs';

import { CreateCustomFieldValueDto, NetworkInfoDto } from '../api/api';
import { Column } from '../types/partnerListColumnType';
import { OrderType } from '../types/sortingType';

// export const toCamelCase = (label: string): string => {
//   return label
//     .split(' ')
//     .map((word, index) =>
//       index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//     )
//     .join('');
// };

export const formatLabelToLowerCase = (label: string): string => {
  return label.replace(/\s+/g, '').toLowerCase();
};

export const formRequestHeader = () => {
  const token = localStorage.getItem('jwt');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
};

export const convertCustomFieldValuesToObject = (
  data: CreateCustomFieldValueDto | Record<string, string> | undefined
): Record<string, string> => {
  if (!data || !Array.isArray(data)) {
    return (data as Record<string, string>) || {};
  }

  return data.reduce(
    (acc, item) => {
      const [key, value] = Object.entries(item)[0];
      acc[key] = value;
      return acc;
    },
    {} as Record<string, string>
  );
};

/**
 * This function sanitizes custom field values by converting them into a consistent format.
 * It accepts an object or array of key-value pairs where the values can be of type string, number, or boolean.
 * The function returns a new object where all values are converted to strings.
 *
 * @param values - An object or array of custom field values, where the values can be string, number, or boolean.
 * @returns A Record<string, string> where all values are converted to strings. If input is undefined, it returns an empty object.
 */
export const sanitizeCustomFieldValues = (
  values: { [key: string]: string | number | boolean } | undefined
): Record<string, string> => {
  if (!values) return {};

  let sanitized: Record<string, string> = {};

  if (Array.isArray(values)) {
    sanitized = values.reduce(
      (acc, item) => {
        const [key, value] = Object.entries(item)[0];
        acc[key] = String(value);
        return acc;
      },
      {} as Record<string, string>
    );
  } else {
    Object.entries(values).forEach(([key, value]) => {
      sanitized[key] = String(value);
    });
  }

  return sanitized;
};

const stringToRandomHue = (base: number, variation: number): number => {
  return (base + variation) % 360;
};

const stringToHSLWithVariation = (str: string, variation: number): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = stringToRandomHue(Math.abs(hash) % 360, variation);
  const saturation = 60 + Math.abs(hash % 30);
  const lightness = 55 + Math.abs(hash % 15);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const generateRadialGradientFromText = (text: string): string => {
  const color1 = stringToHSLWithVariation(text + '1', 0);
  const color2 = stringToHSLWithVariation(text + '2', 60);
  const color3 = stringToHSLWithVariation(text + '3', 120);
  const color4 = stringToHSLWithVariation(text + '4', 180);
  const color5 = stringToHSLWithVariation(text + '5', 240);

  return `
      radial-gradient(circle at 20% 30%, ${color1}, transparent),
      radial-gradient(circle at 80% 20%, ${color2}, transparent),
      radial-gradient(circle at 50% 70%, ${color3}, transparent),
      radial-gradient(circle at 90% 80%, ${color4}, transparent),
      radial-gradient(circle at 30% 90%, ${color5}, transparent)
    `;
};

export const stringToColor = (string: string): string => {
  return stringToHSLWithVariation(string, 0);
};

export const getFilename = (prefix: string): string => {
  const dateTime = new Date()
    .toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/[/,:\s]/g, '_');

  return `${prefix}_${dateTime}.csv`;
};

const isSolanaAddressValid = (address: string): boolean => {
  const solanaAddressRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  return solanaAddressRegex.test(address);
};

const isTonAddressValid = (address: string): boolean => {
  function isFriendly(source: string): boolean {
    if (source.length !== 48) {
      return false;
    }
    if (!/^[A-Za-z0-9+/_-]+$/.test(source)) {
      return false;
    }
    return true;
  }

  function isRaw(source: string): boolean {
    if (!source.includes(':')) {
      return false;
    }

    const [wc, hash] = source.split(':');

    if (isNaN(Number(wc))) {
      return false;
    }

    if (!/^[a-f0-9]+$/.test(hash.toLowerCase())) {
      return false;
    }

    if (hash.length !== 64) {
      return false;
    }

    return true;
  }

  return isFriendly(address) || isRaw(address);
};

const isSeiAddressValid = (address: string): boolean => {
  // EVM address
  if (isEthereumAddressValid(address)) {
    return true;
  }

  // Sei-native address
  if (address.startsWith('sei1') && /^[a-z0-9]{38}$/.test(address.slice(4))) {
    return true;
  }

  return false;
};

export const isAddressValid = (address: string, networkName: string): boolean => {
  const addressValidators: Record<string, (address: string) => boolean> = {
    TON: isTonAddressValid,
    Solana: isSolanaAddressValid,
    Sei: isSeiAddressValid,
  };

  return (addressValidators[networkName] ?? isEthereumAddressValid)(address);
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export function getComparator<T>(order: OrderType, orderBy: keyof T): (a: T, b: T) => number {
  return order === OrderType.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const generateTemplate = (columns: Column[]) => ({
  columns: columns.map((column) => ({
    name: column.label,
    key: column.id,
    required: column.required,
  })),
});

export const getNetworkIdByName = (
  name: string | undefined,
  networksList: NetworkInfoDto[] | undefined
): number | undefined => {
  if (name) {
    const network = networksList?.find((net) => net.name.toLowerCase() === name.toLowerCase());
    if (network) return network.id;
  }
};

const DEFAULT_FORMAT = 'MM-DD-YYYY';
const FALLBACK_FORMATS = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD/MM/YYYY', 'DD-MM-YYYY', 'MM-DD-YYYY'];

export const formatDate = (date: string): string => {
  if (!date) return 'N/A';

  const parsedDate = dayjs(date, FALLBACK_FORMATS, true);

  if (!parsedDate.isValid()) {
    return date;
  }

  return parsedDate.format(DEFAULT_FORMAT);
};

interface DateFormatOptions {
  format?: string;
  parseFormat?: string;
}

/**
 * Formats a date string or Dayjs object into the desired date format.
 * @param date - The date to format.
 * @param options - Optional formatting and parsing options.
 * @returns The formatted date string or an empty string if the date is invalid.
 */
export const formatDates = (date: dayjs.Dayjs | null, options: DateFormatOptions = {}): string => {
  if (!date) return '';

  const { format = DEFAULT_FORMAT } = options;
  return date.isValid() ? date.format(format) : '';
};

/**
 * Parses a date string into a Dayjs object, trying multiple fallback formats.
 * @param dateString - The string to parse.
 * @param parseFormat - The primary format to use for parsing.
 * @returns A Dayjs object or null if the parsing fails.
 */
export const parseDate = (dateString: string, parseFormat: string = DEFAULT_FORMAT): dayjs.Dayjs | null => {
  let parsedDate = dayjs(dateString, parseFormat, true);
  if (!parsedDate.isValid()) {
    for (const format of FALLBACK_FORMATS) {
      parsedDate = dayjs(dateString, format, true);
      if (parsedDate.isValid()) break;
    }
  }

  return parsedDate.isValid() ? parsedDate : null;
};

/**
 * Provides a consistent default format for components.
 * @returns The default date format.
 */
export const getDefaultDateFormat = (): string => DEFAULT_FORMAT;
