import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';

import { TokenListDto, UpdateTokenListItemDto } from '../api/api';
import { CustomFieldLabelDto } from '../api/api';
import { formatDates, getDefaultDateFormat, parseDate, sanitizeCustomFieldValues } from '../utils/helpers';

interface EditableTableProps {
  list: TokenListDto;
  onTokenChange: (id: number, updatedToken: Partial<UpdateTokenListItemDto>) => void;
  onValidationError: (hasError: boolean) => void;
}
dayjs.extend(customParseFormat);

const EditableTable: React.FC<EditableTableProps> = ({ list, onTokenChange, onValidationError }) => {
  const [localChanges, setLocalChanges] = useState<Record<number, Partial<UpdateTokenListItemDto>>>({});
  const [errors, setErrors] = useState<Record<number, Record<number, string>>>({});

  const handleFieldChange = (tokenId: number, field: keyof UpdateTokenListItemDto, value: any) => {
    setLocalChanges((prev) => {
      const prevChanges = prev[tokenId] || {};
      return {
        ...prev,
        [tokenId]: {
          ...prevChanges,
          [field]: value,
          customFieldsValues: prevChanges.customFieldsValues || [],
        },
      };
    });
  };

  const handleCustomFieldChange = (tokenId: number, customFieldId: number, value: string) => {
    const isFieldEmpty = value.trim() === '';

    setErrors((prev) => {
      const updatedErrors = {
        ...prev,
        [tokenId]: {
          ...prev[tokenId],
          [customFieldId]: isFieldEmpty ? 'This field is required' : '',
        },
      };

      const hasErrors = Object.values(updatedErrors).some((fieldErrors) =>
        Object.values(fieldErrors).some((error) => error !== '')
      );

      onValidationError(hasErrors || isFieldEmpty);

      return updatedErrors;
    });

    setLocalChanges((prev) => {
      const prevChanges = prev[tokenId] || {};
      const existingCustomFields = prevChanges.customFieldsValues || [];
      const updatedCustomField = { customFieldId, newValue: value };
      const newCustomFieldsValues = existingCustomFields.filter((field) => field.customFieldId !== customFieldId);
      return {
        ...prev,
        [tokenId]: {
          ...prevChanges,
          customFieldsValues: [...newCustomFieldsValues, updatedCustomField],
        },
      };
    });
  };

  const fieldRenderers: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (customField: CustomFieldLabelDto, tokenId: number, customFieldValue: string) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (customField, tokenId, customFieldValue) => (
      <TextField
        variant="outlined"
        defaultValue={customFieldValue || ''}
        onChange={(e) => handleCustomFieldChange(tokenId, customField.customFieldId, e.target.value)}
        fullWidth
        error={Boolean(errors[tokenId]?.[customField.customFieldId])}
        helperText={errors[tokenId]?.[customField.customFieldId] || ''}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (customField, tokenId, customFieldValue) => (
      <TextField
        variant="outlined"
        type="number"
        defaultValue={customFieldValue || ''}
        onChange={(e) => handleCustomFieldChange(tokenId, customField.customFieldId, e.target.value)}
        fullWidth
        error={Boolean(errors[tokenId]?.[customField.customFieldId])}
        helperText={errors[tokenId]?.[customField.customFieldId] || ''}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (customField, tokenId, customFieldValue) => (
      <TextField
        select
        variant="outlined"
        defaultValue={customFieldValue || 'false'}
        onChange={(e) => handleCustomFieldChange(tokenId, customField.customFieldId, e.target.value)}
        fullWidth
        error={Boolean(errors[tokenId]?.[customField.customFieldId])}
        helperText={errors[tokenId]?.[customField.customFieldId] || ''}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (customField, tokenId, customFieldValue) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={customFieldValue ? parseDate(customFieldValue) : null}
          onChange={(date) => handleCustomFieldChange(tokenId, customField.customFieldId, formatDates(date))}
          format={getDefaultDateFormat()}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              fullWidth: true,
              error: Boolean(errors[tokenId]?.[customField.customFieldId]),
              helperText: errors[tokenId]?.[customField.customFieldId] || '',
            },
          }}
        />
      </LocalizationProvider>
    ),
  };

  useEffect(() => {
    Object.entries(localChanges).forEach(([id, changes]) => {
      onTokenChange(Number(id), changes);
    });
  }, [localChanges, onTokenChange]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Partner</TableCell>
            <TableCell>Overall Usage</TableCell>
            <TableCell>Unique Users</TableCell>
            {list.customFieldsLabels.map((label) => (
              <TableCell key={label.customFieldId}>{label.name}</TableCell>
            ))}
            <TableCell>Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.token.name}</TableCell>
              <TableCell>{item.overallUsage}</TableCell>
              <TableCell>{item.uniqueUsers}</TableCell>
              {list.customFieldsLabels.map((label) => {
                const customField = sanitizeCustomFieldValues(item.customFieldsValues);
                const customFieldValue = customField[label.customFieldId] || label.defaultValue;

                return (
                  <TableCell key={label.customFieldId}>
                    {fieldRenderers[label.customFieldType](label, item.id, customFieldValue)}
                  </TableCell>
                );
              })}
              <TableCell>
                <TextField
                  variant="outlined"
                  defaultValue={item.note || ''}
                  onChange={(e) => handleFieldChange(item.id, 'newNote', e.target.value)}
                  fullWidth
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditableTable;
