import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Button, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';

import { ActionDto, CreateActionDto, CustomFieldLabelDto } from '../api/api';
import { CustomFormType } from '../types/customFormType';
import { DialogAction } from '../types/DialogType';
import { OrderType } from '../types/sortingType';
import { formatDates, getDefaultDateFormat, parseDate } from '../utils/helpers';
import { CustomField } from './DetailsBlock';

interface CustomFormBlockProps {
  type: CustomFormType;
  formState: {
    actions?: (ActionDto | CreateActionDto)[];
    customFields?: CustomField[];
  };
  handleChange: (index: number, key: string, value: any) => void;
  handleAddItem: () => void;
  handleDialogOpen: (index: number, action: DialogAction) => void;
  order?: OrderType;
  orderBy?: keyof ActionDto;
  handleSortRequest?: (property: keyof ActionDto) => void;
}
dayjs.extend(customParseFormat);
const CustomFormBlock: React.FC<CustomFormBlockProps> = ({
  type,
  formState,
  handleChange,
  handleAddItem,
  handleDialogOpen,
  order,
  orderBy,
  handleSortRequest,
}) => {
  const getSortIcon = (column: keyof ActionDto) => {
    if (orderBy !== column) return null;
    return order === OrderType.ASC ? (
      <ArrowUpwardIcon fontSize="inherit" sx={{ verticalAlign: 'middle', ml: 0.5, fontSize: '0.8rem' }} />
    ) : (
      <ArrowDownwardIcon fontSize="inherit" sx={{ verticalAlign: 'middle', ml: 0.5, fontSize: '0.8rem' }} />
    );
  };

  const renderActionName = (action: ActionDto | CreateActionDto, index: number) => {
    return 'id' in action ? (
      <Typography variant="body2" sx={{ fontSize: '1.1rem', fontWeight: '500' }}>
        {action.name}
      </Typography>
    ) : (
      <TextField
        variant="outlined"
        size="small"
        value={action.name}
        onChange={(e) => handleChange(index, 'name', e.target.value)}
      />
    );
  };

  const renderActionNote = (action: ActionDto | CreateActionDto, index: number) => {
    const isDisabled = 'isArchived' in action && action.isArchived;
    return (
      <TextField
        variant="outlined"
        size="small"
        value={action.note}
        onChange={(e) => handleChange(index, 'note', e.target.value)}
        disabled={isDisabled}
      />
    );
  };

  const renderActionCheckbox = (action: ActionDto | CreateActionDto, index: number) => {
    return 'isArchived' in action ? (
      <Checkbox
        checked={!action.isArchived}
        onChange={() => handleDialogOpen(index, action.isArchived ? DialogAction.ADD : DialogAction.REMOVE)}
      />
    ) : (
      <Checkbox disabled checked />
    );
  };

  const renderFieldLabel = (field: CustomField, index: number) => {
    return field.customFieldId ? (
      <Typography>{field.name}</Typography>
    ) : (
      <TextField
        variant="outlined"
        size="small"
        value={field.name}
        error={Boolean(field.errors.name)}
        label={field.errors.name}
        onChange={(e) => handleChange(index, 'name', e.target.value)}
        disabled={field.isArchived}
      />
    );
  };

  const renderFieldType = (field: CustomField, index: number) => {
    return field.customFieldId ? (
      <Typography>{field.customFieldType}</Typography>
    ) : (
      <TextField
        variant="outlined"
        size="small"
        select
        value={field.customFieldType}
        onChange={(e) =>
          handleChange(index, 'customFieldType', e.target.value as unknown as CustomFieldLabelDto.CustomFieldTypeEnum)
        }
        disabled={field.isArchived}>
        {Object.values(CustomFieldLabelDto.CustomFieldTypeEnum).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const fieldTypeMap: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (field: CustomField, index: number) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}
        error={Boolean(field.errors.defaultValue)}
        label={field.errors.defaultValue}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        select
        value={field.defaultValue}
        error={Boolean(field.errors.defaultValue)}
        label={field.errors.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (field, index) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={field.defaultValue ? parseDate(field.defaultValue) : null}
          onChange={(date: Dayjs | null) =>
            handleChange(index, 'defaultValue', date ? formatDates(date, { format: getDefaultDateFormat() }) : '')
          }
          disabled={field.isArchived}
          format={getDefaultDateFormat()}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
            },
          }}
        />
      </LocalizationProvider>
    ),

    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        type="number"
        error={Boolean(field.errors.defaultValue)}
        label={field.errors.defaultValue}
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
        disabled={field.isArchived}
      />
    ),
  };

  const renderFieldDefaultValue = (field: CustomField, index: number) => {
    return fieldTypeMap[field.customFieldType]
      ? fieldTypeMap[field.customFieldType](field, index)
      : fieldTypeMap[CustomFieldLabelDto.CustomFieldTypeEnum.STRING](field, index);
  };

  const renderFieldActiveCheckbox = (field: CustomField, index: number) => (
    <Checkbox
      checked={!field.isArchived}
      onChange={() => handleDialogOpen(index, field.isArchived ? DialogAction.ADD : DialogAction.REMOVE)}
    />
  );

  const headerMap = {
    [CustomFormType.ACTIONS]: [
      { label: 'Name', field: 'name', isSortable: true },
      { label: 'Note', field: 'note', isSortable: false },
      { label: 'Active', field: 'isArchived', isSortable: true },
    ],
    [CustomFormType.PARAMS]: [
      { label: 'Label', field: 'name', isSortable: false },
      { label: 'Type', field: 'customFieldType', isSortable: false },
      { label: 'Default Value', field: 'defaultValue', isSortable: false },
      { label: 'Active', field: 'isArchived', isSortable: false },
    ],
  };
  const configMap = {
    [CustomFormType.ACTIONS]: {
      label: 'Actions',
      gridTemplateColumns: '1fr 2fr auto',
    },
    [CustomFormType.PARAMS]: {
      label: 'Custom Params',
      gridTemplateColumns: '1fr 1fr 1fr auto',
    },
  };

  const rowRendererMap = {
    [CustomFormType.ACTIONS]: () =>
      formState.actions!.map((action, index) => (
        <React.Fragment key={'id' in action ? action.id : `local_${index}`}>
          {renderActionName(action, index)}
          {renderActionNote(action, index)}
          {renderActionCheckbox(action, index)}
        </React.Fragment>
      )),
    [CustomFormType.PARAMS]: () =>
      formState.customFields!.map((field, index) => (
        <React.Fragment key={field.customFieldId || `local_${index}`}>
          {renderFieldLabel(field, index)}
          {renderFieldType(field, index)}
          {renderFieldDefaultValue(field, index)}
          {renderFieldActiveCheckbox(field, index)}
        </React.Fragment>
      )),
  };

  return (
    <Box>
      <Typography variant="subtitle1" color="textSecondary">
        {configMap[type].label}
      </Typography>

      <Box display="grid" gridTemplateColumns={configMap[type].gridTemplateColumns} gap={1.5} alignItems="center">
        {headerMap[type].map((header) => (
          <Box
            key={header.label}
            display="flex"
            alignItems="center"
            sx={{ cursor: header.isSortable ? 'pointer' : 'default' }}
            onClick={() => {
              if (header.isSortable) handleSortRequest!(header.field as keyof ActionDto);
            }}>
            <Typography variant="subtitle2">{header.label}</Typography>
            {header.isSortable && getSortIcon(header.field as keyof ActionDto)}
          </Box>
        ))}
        {rowRendererMap[type]()}
      </Box>

      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleAddItem}
          sx={{
            padding: '0.25rem 8rem',
            minWidth: '300px',
            height: '1.4rem',
          }}>
          +
        </Button>
      </Box>
    </Box>
  );
};

export default CustomFormBlock;
