import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { CustomFieldLabelDto, TokenListDto } from '../api/api';
import { usePartnersList } from '../hooks/usePartnersList';
import { useUserInfo } from '../hooks/useUserInfo';
import { CustomFormType } from '../types/customFormType';
import { DialogAction } from '../types/DialogType';
import { ListModalType } from '../types/modalType';
import ConfirmDialog from './ConfirmDialog';
import CustomFormBlock from './CustomFormBlock';

export interface CustomField {
  customFieldId?: number;
  name: string;
  customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum;
  defaultValue: string;
  isArchived: boolean;
  errors: { [key: string]: string };
}

interface DetailsBlockProps {
  type: ListModalType;
  tokenList: TokenListDto | undefined;
  onClose: () => void;
}

const DetailsBlock: React.FC<DetailsBlockProps> = ({ tokenList, onClose, type }) => {
  const { userInfo } = useUserInfo();
  const { createList, updateList, updateCustomFields, createNewCustomFields } = usePartnersList(
    tokenList?.id?.toString()
  );
  const [formState, setFormState] = useState({
    name: '',
    note: '',
    customFields: [] as CustomField[],
  });
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null);
  const [dialogAction, setDialogAction] = useState<DialogAction | null>(null);
  const [isListCreated, setIsListCreated] = useState(false);

  useEffect(() => {
    if (tokenList) {
      const initialCustomFields: CustomField[] = tokenList.customFieldsLabels
        .map((field) => ({
          customFieldId: field.customFieldId,
          name: field.name,
          customFieldType: field.customFieldType,
          defaultValue: field.defaultValue,
          isArchived: field.isArchived,
          errors: {},
        }))
        .sort((a, b) => (a.customFieldId ?? 0) - (b.customFieldId ?? 0));

      setFormState({
        name: tokenList.name,
        note: tokenList.note || '',
        customFields: initialCustomFields,
      });
    }
  }, [tokenList]);

  useEffect(() => {
    const createFieldsForNewList = async () => {
      if (isListCreated && userInfo) {
        const latestList = userInfo?.projects[0].tokenLists[userInfo?.projects[0].tokenLists.length - 1];
        if (latestList?.id && formState.customFields.length > 0) {
          await createNewCustomFields(
            {
              customFields: formState.customFields.map((field) => ({
                name: field.name,
                customFieldType: field.customFieldType,
                defaultValue: field.defaultValue,
              })),
            },
            String(latestList.id)
          );
          setIsListCreated(false);
        }
      }
    };

    createFieldsForNewList();
  }, [isListCreated, userInfo]);

  const handleAddField = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      customFields: [
        ...prevState.customFields,
        {
          name: '',
          customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum.STRING,
          defaultValue: '',
          isArchived: false,
          errors: {},
        },
      ],
    }));
  }, []);

  const handleChange = (index: number, key: string, value: string | CustomFieldLabelDto.CustomFieldTypeEnum) => {
    let updatedCustomFields = [] as CustomField[];
    setFormState((prevState) => {
      updatedCustomFields = prevState.customFields.map((field, i) =>
        i === index ? { ...field, [key]: value } : field
      );
      return {
        ...prevState,
        customFields: updatedCustomFields,
      };
    });
    validateCustomFieldProp(key as 'name' | 'defaultValue', updatedCustomFields[index]);
  };

  const validateCustomFieldProp = (prop: 'name' | 'defaultValue', field: CustomField) => {
    if (!field[prop]) {
      field.errors[prop] = `${prop.charAt(0).toUpperCase() + prop.slice(1)} is required`;
    } else {
      delete field.errors[prop];
    }

    setFormState((prevState) => ({
      ...prevState,
      customFields: prevState.customFields.map((f) => (f === field ? field : f)),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (
        formState.customFields.filter((field) => {
          validateCustomFieldProp('name', field);
          validateCustomFieldProp('defaultValue', field);
          return Object.keys(field.errors).length > 0;
        }).length > 0
      )
        throw new Error('Validation failed');

      const actions: Record<ListModalType, Array<{ condition: boolean; action: () => Promise<void> }>> = {
        [ListModalType.ADD_LIST]: [
          {
            condition: true,
            action: async () => {
              await createList({
                name: formState.name,
                note: formState.note,
              });

              setIsListCreated(true);
            },
          },
        ],
        [ListModalType.EDIT_LIST]: [
          {
            condition: formState.name !== tokenList?.name || formState.note !== tokenList?.note,
            action: async () =>
              updateList({
                newName: formState.name,
                newNote: formState.note,
                isArchived: false,
              }),
          },
          {
            condition: formState.customFields.some((field) => field.customFieldId),
            action: async () =>
              updateCustomFields({
                customFields: formState.customFields
                  .filter((field) => field.customFieldId)
                  .map((field) => ({
                    customFieldId: field.customFieldId!,
                    newName: field.name,
                    newDefaultValue: field.defaultValue,
                    isArchived: field.isArchived,
                  }))
                  .sort((a, b) => (a.customFieldId ?? 0) - (b.customFieldId ?? 0)),
              }),
          },
          {
            condition: formState.customFields.some((field) => !field.customFieldId),
            action: async () =>
              createNewCustomFields({
                customFields: formState.customFields
                  .filter((field) => !field.customFieldId)
                  .map((field) => ({
                    name: field.name,
                    customFieldType: field.customFieldType,
                    defaultValue: field.defaultValue,
                  })),
              }),
          },
        ],
      };

      const selectedActions = actions[type];

      const promises = selectedActions.filter((item) => item.condition).map((item) => item.action());
      await Promise.all(promises);
      onClose();
    } catch (error) {
      console.error('Submission failed', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = (index: number, action: DialogAction) => {
    setSelectedFieldIndex(index);
    setDialogOpen(true);
    setDialogAction(action);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedFieldIndex(null);
    setDialogAction(null);
  };

  const handleDialogConfirm = async () => {
    if (selectedFieldIndex !== null && dialogAction) {
      setLoading(true);

      const updatedFields = formState.customFields.map((field, i) =>
        i === selectedFieldIndex ? { ...field, isArchived: dialogAction === DialogAction.REMOVE } : field
      );

      setFormState((prevState) => ({
        ...prevState,
        customFields: updatedFields,
      }));

      try {
        const customFieldsToUpdate = updatedFields.filter((field) => field.customFieldId);

        if (customFieldsToUpdate.length > 0) {
          await updateCustomFields({
            customFields: customFieldsToUpdate.map((field) => ({
              customFieldId: field.customFieldId!,
              newName: field.name,
              newDefaultValue: field.defaultValue,
              isArchived: field.isArchived,
            })),
          });
        }
      } catch {
        alert('Failed to update custom fields. Please try again.');
      } finally {
        setLoading(false);
      }

      handleDialogClose();
    }
  };

  return (
    <Box sx={{ width: '33rem' }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            Name
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={formState.name}
            onChange={(e) => setFormState((prevState) => ({ ...prevState, name: e.target.value }))}
          />
        </Box>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            Description
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows={1}
            maxRows={5}
            value={formState.note}
            onChange={(e) => setFormState((prevState) => ({ ...prevState, note: e.target.value }))}
          />
        </Box>

        <Divider sx={{ width: '100%' }} />

        <CustomFormBlock
          type={CustomFormType.PARAMS}
          formState={formState}
          handleChange={handleChange}
          handleAddItem={handleAddField}
          handleDialogOpen={handleDialogOpen}
        />
      </Box>

      <Box display="flex" justifyContent="center" gap={2} mt={3}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? 'Submitting...' : 'Confirm'}
        </Button>
      </Box>

      <ConfirmDialog
        open={dialogOpen}
        action={dialogAction}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </Box>
  );
};

export default DetailsBlock;
