import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { NetworkIcon } from '@web3icons/react';
import React, { useEffect, useMemo } from 'react';

import { NetworkInfoDto, TokenListDto, TokenListItemDto } from '../api/api';
import { CustomFieldLabelDto } from '../api/api';
import { useNetworksList } from '../hooks/useNetworksList';
import { Column } from '../types/partnerListColumnType';
import { formatDate, formatLabelToLowerCase, sanitizeCustomFieldValues } from '../utils/helpers';

const getNetworkIcon = (networkId: number, networksList: NetworkInfoDto[] | undefined) => {
  const network = networksList?.find((network) => network.id === networkId);

  try {
    if (network) {
      return <NetworkIcon network={network.name} variant="branded" />;
    }
  } catch {
    console.debug(`Network icon for ${network?.name} not found, using Ethereum mono icon`);
  }

  return <NetworkIcon network="Ethereum" variant="mono" />;
};

export interface PartnerListProps {
  list: TokenListDto;
  handleRowClick: (row: TokenListItemDto) => void;
  setImportColumns: (columns: Column[]) => void;
}

const PartnerList: React.FC<PartnerListProps> = ({ list, handleRowClick, setImportColumns }) => {
  const { networksList } = useNetworksList();
  const columns: Column[] = useMemo(
    () => [
      {
        id: 'chainIcon',
        label: '',
        accessor: (item: TokenListItemDto) => getNetworkIcon(item.token.networkId, networksList),
        sx: { width: '1%', paddingBottom: '0.65rem' },
      },
      {
        id: 'address',
        label: 'Partner',
        accessor: (item: TokenListItemDto) => item.token.name,
        required: true,
        suggested_mappings: ['Partner', 'Address', 'partner'],
      },
      { id: 'overallUsage', label: 'Overall Usage', accessor: (item: TokenListItemDto) => item.overallUsage },
      { id: 'uniqueUsers', label: 'Unique Users', accessor: (item: TokenListItemDto) => item.uniqueUsers },
      ...list.customFieldsLabels.map((label) => {
        const key = formatLabelToLowerCase(label.name + '_' + label.customFieldType);
        return {
          id: key,
          label: label.name,
          accessor: (item: TokenListItemDto) => {
            const customField = sanitizeCustomFieldValues(item.customFieldsValues);
            const fieldValue = customField[label.customFieldId];

            if (label.customFieldType === CustomFieldLabelDto.CustomFieldTypeEnum.DATE) {
              const formattedDate = fieldValue ? formatDate(fieldValue) : '';
              return formattedDate || fieldValue || formatDate(label.defaultValue);
            }
            return fieldValue || label.defaultValue;
          },
          required: false,
          suggested_mappings: [label.name],
        };
      }),
      { id: 'note', label: 'Note', accessor: (item: TokenListItemDto) => item.note, required: false },
    ],
    [networksList, list.customFieldsLabels]
  );

  useEffect(() => {
    const importColumns = [
      {
        id: 'networkName',
        label: 'Network Name',
        required: true,
        accessor: () => '',
        suggested_mappings: ['Network Name', 'network_name', 'Network'],
      },
      ...columns.filter((column) => !['chainIcon', 'overallUsage', 'uniqueUsers'].includes(column.id)),
    ];

    setImportColumns(importColumns);
  }, [columns, setImportColumns, networksList]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.items.map((item) => (
            <TableRow
              hover
              key={item.id}
              onClick={() => handleRowClick(item)}
              sx={{ backgroundColor: item.isActive ? 'inherit' : '#f0f0f0' }}>
              {columns.map((column) => {
                const value = column.accessor ? column.accessor(item) : (item as any)[column.id];
                return (
                  <TableCell sx={column.sx} key={column.id}>
                    {column.format ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnerList;
