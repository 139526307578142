import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';

import AnalyticsPieChart from '../components/AnalyticsPieChart';
import AnalyticsTable from '../components/AnalyticsTable';
import TabsComponent from '../components/TabsComponent';
import { useAnalytics } from '../hooks/useAnalytics';
import { useUserInfo } from '../hooks/useUserInfo';
import { AnalyticsTabs } from '../types/analityticsTabs';

const AnalyticsPage: React.FC = () => {
  const tabTitles = [AnalyticsTabs.Partners, AnalyticsTabs.Users];

  const { userInfo } = useUserInfo();
  const projectId = userInfo?.projects[0]?.id?.toString();

  const { tokensAnalytics, usersAnalytics, isLoading } = useAnalytics(projectId);

  const [selectedTab, setSelectedTab] = useState<AnalyticsTabs>(tabTitles[0]);
  const [selectedEvent, setSelectedEvent] = useState('Total');

  const handleTabChange = (newTab: AnalyticsTabs) => {
    setSelectedTab(newTab);
  };

  const handleEventChange = (event: any) => {
    setSelectedEvent(event.target.value);
  };

  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Analytics
      </Typography>

      <TabsComponent titles={tabTitles} selectedTab={selectedTab} onChange={handleTabChange} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 3 }}>
        <AnalyticsTable
          type={selectedTab}
          analyticsData={selectedTab === AnalyticsTabs.Partners ? tokensAnalytics : usersAnalytics}
          isLoading={isLoading}
        />

        {selectedTab === AnalyticsTabs.Partners && tokensAnalytics?.labels && tokensAnalytics?.stats && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 2 }}>
              <FormControl sx={{ minWidth: 240 }}>
                <InputLabel>Action</InputLabel>
                <Select value={selectedEvent} onChange={handleEventChange} label="Event">
                  <MenuItem value="Total">Total</MenuItem>
                  {tokensAnalytics.labels.map((label: any) => (
                    <MenuItem key={label.id} value={label.title}>
                      {label.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                alignSelf: 'flex-start',
                width: '31.25rem',
                height: '31.25rem',
                marginTop: 5,
                marginLeft: 14,
              }}>
              <AnalyticsPieChart analyticsData={tokensAnalytics} selectedAction={selectedEvent} />
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default AnalyticsPage;
