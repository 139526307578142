import ErrorOutline from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';

import { CreateCustomFieldValueDto, CustomFieldLabelDto, TokenInfoDto, TokenListItemDto } from '../../api/api';
import { useNetworksList } from '../../hooks/useNetworksList';
import { useTokenInfo } from '../../hooks/useTokenInfo';
import { PartnerModalType } from '../../types/modalType';
import {
  formatDates,
  getDefaultDateFormat,
  isAddressValid,
  parseDate,
  sanitizeCustomFieldValues,
} from '../../utils/helpers';

interface PartnerContentProps {
  listId: number;
  actionType: PartnerModalType;
  onSubmit: (formValues: any) => void;
  onCancel: () => void;
  partnerData?: TokenListItemDto;
  customFieldsLabels?: CustomFieldLabelDto[];
}

const defaultToken: TokenInfoDto = {
  name: '',
  address: '',
  type: TokenInfoDto.TypeEnum.ERC20,
  symbol: '',
  networkId: 1,
  decimals: 18,
};

dayjs.extend(customParseFormat);

const PartnerContent: React.FC<PartnerContentProps> = ({
  listId,
  actionType,
  customFieldsLabels,
  partnerData,
  onSubmit,
  onCancel,
}) => {
  const { networksList } = useNetworksList();

  const [formValues, setFormValues] = useState<TokenListItemDto>({
    id: partnerData?.id ?? 0,
    note: partnerData?.note || '',
    token: partnerData?.token || defaultToken,
    customFieldsValues: sanitizeCustomFieldValues(partnerData?.customFieldsValues),
    isActive: partnerData?.isActive ?? true,
    overallUsage: partnerData?.overallUsage ?? 0,
    uniqueUsers: partnerData?.uniqueUsers ?? 0,
  });

  const [debouncedAddress, setDebouncedAddress] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAddressInvalid, setIsAddressInvalid] = useState<boolean>(false);

  const selectedNetwork = networksList?.find((net) => net.id === formValues.token.networkId);
  const networkName = selectedNetwork?.name;

  const onChange = useMemo(
    () =>
      debounce((value: string) => {
        if (networkName) {
          const isValid = isAddressValid(value, networkName);
          setDebouncedAddress(isValid ? value : null);
          setIsAddressInvalid(!isValid);
          setErrorMessage(isValid ? null : `Invalid ${networkName} address`);
        }
      }, 500),
    [networkName]
  );

  const handleChange =
    (field: keyof TokenListItemDto | keyof TokenInfoDto | keyof CreateCustomFieldValueDto) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: any) => {
      const inputValue = event?.target?.value ?? value;

      setFormValues((prevValues) => ({
        ...prevValues,
        token: field in defaultToken ? { ...prevValues.token, [field]: inputValue } : prevValues.token,
        customFieldsValues: { ...prevValues.customFieldsValues, [event.target.name]: inputValue },
        ...(field !== 'customFieldsValues' && !(field in defaultToken) ? { [field]: inputValue } : {}),
      }));

      if (field === 'address') {
        setErrorMessage(null);
        onChange(inputValue);
      }
    };

  const { tokenInfo, isLoading, error } = useTokenInfo(formValues.token.networkId.toString(), debouncedAddress || '');
  const updateTokenInfo = (newTokenInfo: Partial<TokenInfoDto>) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      token: {
        ...prevValues.token,
        ...newTokenInfo,
      },
    }));
  };

  const handleEditPartnerToken = () => {
    if (partnerData) {
      const { name, symbol, address, networkId } = partnerData.token;
      updateTokenInfo({ name, symbol, address, networkId });
    }
  };

  const handleTokenInfoFetch = () => {
    if (tokenInfo && !isLoading && !error) {
      updateTokenInfo({ name: tokenInfo.name, symbol: tokenInfo.symbol });
      setErrorMessage(null);
    } else if (error) {
      setErrorMessage('Token does not exist in the selected network');
      updateTokenInfo({ name: '', symbol: '' });
    }
  };

  useEffect(() => {
    if (actionType === PartnerModalType.EDIT_PARTNER) {
      handleEditPartnerToken();
      return;
    }

    if (debouncedAddress) {
      handleTokenInfoFetch();
    }
  }, [debouncedAddress, tokenInfo, isLoading, error, actionType, partnerData]);

  const handleCustomFieldChange = (id: number) => (event: any, value?: any) => {
    const inputValue = event?.target?.value ?? value;

    setFormValues((prevValues) => ({
      ...prevValues,
      customFieldsValues: {
        ...prevValues.customFieldsValues,
        [id]: inputValue,
      },
    }));
  };

  const handleSelectChange = (field: keyof TokenInfoDto) => (event: SelectChangeEvent<number>) => {
    const value = event.target.value;

    setFormValues((prevValues) => ({
      ...prevValues,
      token: { ...prevValues.token, [field]: value },
    }));
  };

  const prepareCustomFieldsArray = () => {
    return Object.entries(formValues.customFieldsValues)
      .map(([key, value]) => {
        const label = customFieldsLabels?.find((label) => label.customFieldId === Number(key));

        const params = {
          [PartnerModalType.ADD_PARTNER]: { customFieldId: label?.customFieldId, value },
          [PartnerModalType.EDIT_PARTNER]: { customFieldId: label?.customFieldId, newValue: value },
        };

        if (label) return params[actionType];
      })
      .filter(Boolean);
  };

  const handleSubmit = async () => {
    const customFieldsArray = prepareCustomFieldsArray();

    const params = {
      [PartnerModalType.ADD_PARTNER]: {
        listId,
        networkId: Number(formValues.token.networkId),
        address: formValues.token.address,
        note: formValues.note,
        customFieldsValues: customFieldsArray,
      },
      [PartnerModalType.EDIT_PARTNER]: {
        newNote: formValues.note,
        isActive: String(formValues.isActive) === 'true',
        isArchived: false,
        customFieldsValues: customFieldsArray,
      },
    };

    const preparedData = params[actionType];

    onSubmit(preparedData);
  };

  const fieldConfigurations: { name: keyof TokenInfoDto; label: string }[] = [
    { name: 'address', label: 'Address' },
    { name: 'networkId', label: 'Network' },
    { name: 'symbol', label: 'Symbol' },
    { name: 'name', label: 'Name' },
  ];

  const fieldRenderMapping: Record<string, (name: keyof TokenInfoDto, disabled: boolean) => React.ReactNode> = {
    networkId: (name, disabled) => (
      <FormControl fullWidth variant="outlined">
        <Select
          id={name}
          value={formValues.token.networkId}
          onChange={handleSelectChange(name)}
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? '#f5f5f5' : 'inherit',
            color: disabled ? '#888' : 'inherit',
          }}>
          {networksList?.map((network) => (
            <MenuItem key={network.id} value={network.id}>
              {network.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    default: (name, disabled) => (
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          color="primary"
          id={name}
          value={formValues.token[name] || ''}
          onChange={handleChange(name)}
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? '#f5f5f5' : 'inherit',
            color: disabled ? '#888' : 'inherit',
          }}
        />
      </FormControl>
    ),
  };

  const renderFormField = (fieldConfig: { name: keyof TokenInfoDto; label: string }) => {
    const { name, label } = fieldConfig;
    const disabled = isFieldDisabled(name);

    return (
      <Grid container key={name} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          {label}
        </Grid>
        <Grid item xs={10}>
          {name === 'address' ? (
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                color="primary"
                id={name}
                value={formValues.token.address || ''}
                onChange={handleChange(name)}
                error={isAddressInvalid}
                sx={{
                  backgroundColor: disabled ? '#f5f5f5' : 'inherit',
                  color: disabled ? '#888' : 'inherit',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isAddressInvalid ? 'red' : 'inherit',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: isAddressInvalid ? 'red' : 'inherit',
                  },
                }}
              />
            </FormControl>
          ) : fieldRenderMapping[name] ? (
            fieldRenderMapping[name](name, disabled)
          ) : (
            fieldRenderMapping.default(name, disabled)
          )}
        </Grid>
      </Grid>
    );
  };

  const isFieldDisabled = (field: string) => {
    const disabledFields: Record<PartnerModalType, string[]> = {
      [PartnerModalType.EDIT_PARTNER]: ['name', 'symbol', 'address', 'networkId'],
      [PartnerModalType.ADD_PARTNER]: ['name', 'symbol'],
    };

    return disabledFields[actionType]?.includes(field) || false;
  };

  const activeCustomFieldsLabels = customFieldsLabels?.filter((field) => !field.isArchived);

  const fieldTypeMap: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (field: CustomFieldLabelDto, fieldValue: any, handleChange: any) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        name={field.name}
        value={fieldValue || ''}
        onChange={handleChange}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        type="number"
        name={field.name}
        value={fieldValue || ''}
        onChange={handleChange}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        select
        name={field.name}
        value={fieldValue || 'false'}
        onChange={handleChange}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (field, fieldValue, handleChange) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={parseDate(fieldValue)}
          onChange={(date: Dayjs | null) =>
            handleChange({
              target: {
                name: field.name,
                value: date ? formatDates(date, { format: getDefaultDateFormat() }) : '',
              },
            })
          }
          format={getDefaultDateFormat()}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              fullWidth: true,
            },
          }}
        />
      </LocalizationProvider>
    ),
  };

  const renderCustomField = (field: CustomFieldLabelDto) => {
    const customFieldValueId = field.customFieldId;
    const fieldValue =
      formValues.customFieldsValues[customFieldValueId] ||
      customFieldsLabels?.find((label) => label.customFieldId === field.customFieldId)?.defaultValue ||
      '';
    const handleChangeForField = handleCustomFieldChange(customFieldValueId);

    const inputComponent = fieldTypeMap[field.customFieldType]
      ? fieldTypeMap[field.customFieldType](field, fieldValue, handleChangeForField)
      : fieldTypeMap[CustomFieldLabelDto.CustomFieldTypeEnum.STRING](field, fieldValue, handleChangeForField);

    return (
      <Grid container key={field.customFieldId} sx={{ mb: 2, borderBottom: '1px solid #ddd' }}>
        <Grid item xs={4} sx={{ padding: '8px 16px' }}>
          {field.name}
        </Grid>
        <Grid item xs={4} sx={{ padding: '8px 16px', textTransform: 'capitalize' }}>
          {field.customFieldType.toString().toLowerCase()}
        </Grid>
        <Grid item xs={4} sx={{ padding: '0 16px 16px 16px' }}>
          {inputComponent}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2, p: '1rem' }}>
      <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {errorMessage && (
          <Grid item xs={12}>
            <Box
              sx={{
                mt: -4,
                mb: 2,
                p: 2,
                border: '1px solid #f5c2c7',
                borderRadius: '4px',
                backgroundColor: '#f8d7da',
                color: '#842029',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutline sx={{ mr: 1 }} />
                {errorMessage}
              </Typography>
            </Box>
          </Grid>
        )}

        {fieldConfigurations.map(renderFormField)}
        <Divider sx={{ width: '100%', margin: '1rem 0' }} />

        <Typography variant="h6" sx={{ mb: 2 }}>
          Custom Fields
        </Typography>
        <Grid container sx={{ mb: 2, borderBottom: '2px solid #ddd' }}>
          {['Label', 'Type', 'Value'].map((header) => (
            <Grid key={header} item xs={4} sx={{ padding: '8px 16px', fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
              {header}
            </Grid>
          ))}
        </Grid>
        {activeCustomFieldsLabels?.map(renderCustomField)}
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={2}>
            <Typography>Notes</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="note"
                value={formValues.note || ''}
                onChange={handleChange('note')}
                placeholder="Write a few notes about this token..."
                multiline
                rows={4}
                sx={{
                  backgroundColor: isFieldDisabled('note') ? '#f5f5f5' : 'inherit',
                  color: isFieldDisabled('note') ? '#888' : 'inherit',
                }}
              />
            </FormControl>
          </Grid>
          {actionType === PartnerModalType.EDIT_PARTNER && (
            <>
              <Divider sx={{ width: '100%', margin: '1rem 0' }} />
              <Grid item xs={2}>
                <Typography>Active</Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    select
                    name="isActive"
                    value={formValues.isActive}
                    onChange={handleChange('isActive')}>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onCancel} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {actionType === PartnerModalType.ADD_PARTNER ? 'Add Partner' : 'Save Changes'}
        </Button>
      </Box>
    </Box>
  );
};

export default PartnerContent;
